import styled from '@emotion/styled'
import React, { ReactNode } from 'react'
import { Colors } from 'src/constants/theme'
import img from '/public/logos/logo-icon-blue.png'

const StyledFullScreen = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(51, 51, 51, 0.7);
  z-index: 100000;
`

const StyledLoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${Colors.white500};

  background-image: url(${img.src});
  background-position:
    50% calc(50% - var(--y-offset, 3em)),
    50% calc(50% + var(--y-offset, 0.3em));
  background-size:
    var(--img-size, 3em) var(--img-size, 3em),
    var(--circle-size, 1.5em) var(--circle-size, 1.5em);
  background-repeat: no-repeat;

  animation: bounce 1s infinite cubic-bezier(0.4, 0, 0.2, 1);

  @keyframes bounce {
    0%,
    100% {
      background-position:
        50% calc(50% - var(--img-offset, 4em)),
        50% calc(50% + var(--y-offset, 0.3em));
      background-size:
        var(--img-size, 3em) var(--img-size, 3em),
        var(--circle-size, 1.5em) var(--circle-size, 1.5em);
    }
    50% {
      background-position:
        50% 50%,
        50% calc(50% + var(--y-offset, 0.3em));
      background-size:
        var(--img-size, 3em) var(--img-size, 2.7em),
        var(--circle-size, 4em) var(--circle-size, 2.7em);
    }
  }
`

function Loader() {
  return <StyledLoaderContainer />
}

function FullScreenLoader() {
  return (
    <StyledFullScreen>
      <StyledLoaderContainer />
    </StyledFullScreen>
  )
}

function LoaderContainer({
  loading,
  children,
  fullScreen
}: {
  loading: boolean
  children?: ReactNode
  fullScreen?: boolean
}) {
  if (loading) {
    return fullScreen ? <FullScreenLoader /> : <StyledLoaderContainer />
  }

  return <>{children}</>
}

const MemoContainer = React.memo(LoaderContainer)

export { FullScreenLoader, Loader, LoaderContainer, MemoContainer }
