import { useRouter } from 'next/router'
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Loader } from '../components/Loaders/Loader'

type ILoaderContext = {
  loading: boolean
  setLoading: (loading: boolean) => void
  redirecting: boolean
  setRedirecting: (loading: boolean) => void
}

export const LoaderContext = createContext<ILoaderContext>({
  loading: false,
  setLoading: () => {},
  redirecting: false,
  setRedirecting: () => {}
})

const LoaderProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  const [redirecting, setRedirecting] = useState(false)

  useEffect(() => {
    /* removes loader when landed on new page and rendered */
    setRedirecting(false)
  }, [router.query])

  const value = useMemo(
    () => ({
      loading,
      setLoading,
      redirecting,
      setRedirecting
    }),
    [loading, setLoading, redirecting, setRedirecting]
  )

  return (
    <LoaderContext.Provider value={value}>
      {loading || redirecting ? <Loader /> : children}
    </LoaderContext.Provider>
  )
}

export default React.memo(LoaderProvider)

export const useLoaderProvider = (): ILoaderContext => useContext(LoaderContext)
